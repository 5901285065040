<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16 flexV">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增 </el-button>
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>
        <el-button type="warning" size="small" @click="copyRow"><i class="el-icon-document-copy"></i> 复制</el-button>
        <el-button type="info" size="small" @click="buttonRefresh()"> <i class="el-icon-refresh-right"></i>刷新</el-button>
        <el-button type="success" size="small" @click="showSyncMtrbDataDialog" icon="el-icon-refresh">同步</el-button>
        <div style="margin-left: 20px">
          <span>生效:{{ takeTotal }}</span>
          <span>草拟:{{ draftTotal }}</span>
        </div>
      </div>
      <search-table
        ref="multiTable"
        :tableRowKey="`mtrb_id`"
        :data="tableData"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :need-fixed-height="true"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        @getTableData="getMtrbsNow"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
      <el-dialog title="同步材料信息" width="70%" top="0" :visible.sync="syncMtrbDialogVisible" @opened="getSyncMtrbData">
        <search-table
          v-if="syncMtrbDialogVisible"
          ref="syncMtrbTable"
          :tableRowKey="`autoInc`"
          :data="syncMtrbTableData"
          :totalPage="syncMtrbTotalPage"
          :columns="syncMtrbTableProperties"
          v-loading="syncLoadFlag"
          @row-dblclick="syncMtrbData"
          @getTableData="getSyncMtrbData"
          :need-fixed-height="true"
        />
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch, postNoCatch } from '@api/request';
import { mtrbAPI } from '@api/modules/mtrb';
import { stffAPI } from '@api/modules/staff';
import { suppAPI } from '@api/modules/supp';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { syncMtrbTableProperties, tableProperties } from '@/views/MessageManagement/MtrbManage/mtrb';
import { getDataCenterByPermId } from '@api/public';
import { optnAPI } from '@api/modules/optn';
import UrlEncode from '@assets/js/UrlEncode';
import { cloneDeep } from 'lodash';

export default {
  name: 'MtrbList',
  components: {
    SearchTable
  },
  data() {
    return {
      syncMtrbDialogVisible: false,
      syncMtrbTableData: [],
      tableProperties: tableProperties,
      syncMtrbTableProperties: syncMtrbTableProperties,
      syncLoadFlag: true,
      loadFlag: true,
      currentPage: 1,
      syncMtrbTotalPage: 0,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {},
      stffUserList: [],
      suppList: [],
      draftTotal: '0',
      takeTotal: '0'
    };
  },
  mounted() {
    if (this.$route.query.mtrb_ids) {
      this.$refs.multiTable.searchForm.mtrb_ids = this.$route.query.mtrb_ids;
    }
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/mtrb_add' || from.path === '/mtrb_edit') {
        this.initData();
      }
    }
  },
  methods: {
    async initData() {
      this.getMtrbs();
      this.getSupp();
      this.getStffUser();
      this.tableProperties.find(x => x.label === '包材品类').options = await getDataCenterByPermId({
        id: 10024,
        type: 'select',
        repalceApi: optnAPI.getOptnByPermIdV1
      });
    },
    getMtrbs() {
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      searchForm.custAbbrList = searchForm.custAbbrList?.toString();
      getNoCatch(mtrbAPI.getMtrbs, searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.takeTotal = data.takeTotal;
        this.draftTotal = data.draftTotal;
        this.tableProperties.find(x => x.label === '客户简称').options = data.custAbbrList;
        this.btn = data.btn;
        setTimeout(() => {
          this.loadFlag = false;
        }, 500);
      });
    },
    getSupp() {
      getNoCatch(suppAPI.getSuppsV1).then(({ data }) => {
        this.suppList = data;
      });
    },
    getMtrbsNow() {
      this.loadFlag = true;
      this.currentPage = 1;
      this.getMtrbs();
    },
    buttonRefresh() {
      this.loadFlag = true;
      this.currentPage = 1;
      this.$refs.multiTable.resetFields();
      this.initData();
    },
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    addRow() {
      const permId = this.$route.query.perm_id;
      this.jump(`/mtrb_add?perm_id=${permId}`);
    },
    copyRow() {
      if (this.multiSelection.length !== 1) return this.$message.warning('只能选择一条');
      postNoCatch(mtrbAPI.copyMtrb, { mtrb_id: this.multiSelection[0].mtrb_id }).then(({ data }) => {
        this.$message.success('复制成功!');
        this.initData();
      });
    },
    doDelete() {
      if (this.multiSelection.length === 0) return this.$message.warning('请至少选择一条数据！');
      this.mBox();
    },
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delRow();
        })
        .catch(() => {
          this.$message.info('已取消删除');
          this.$refs.multiTable.clearSelection();
        });
    },
    /**
     * 删除
     */
    delRow() {
      const ids = Array.from(this.multiSelection, ({ mtrb_id }) => mtrb_id);
      postNoCatch(mtrbAPI.deleteMtrbByIds, { mtrb_id_list: ids }).then(() => {
        this.initData();
      });
    },
    /**
     * 双击row跳转到详情编辑
     */
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/mtrb_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: permId,
            form_id: row.mtrb_id,
            mtrb_no: row.mtrb_no
          })
        )
      });
    },
    getStffUser() {
      getNoCatch(stffAPI.getAllStffsV1).then(({ data }) => {
        this.stffUserList = data;
      });
    },
    showSyncMtrbDataDialog() {
      this.syncMtrbDialogVisible = true;
    },
    syncMtrbData(row) {
      this.syncMtrbDialogVisible = false;
      row.mtrb_other = row.mtrb_spec;
      this.$router.push({
        name: 'MtrbAdd',
        query: { perm_id: this.$route.query.perm_id, autoInc: row.autoInc },
        params: { syncMtrbData: row }
      });
    },
    async getSyncMtrbData() {
      await (this.syncLoadFlag = true);
      await mtrbAPI.getRichErpMtrb(this.$refs.syncMtrbTable.searchForm).then(({ data }) => {
        this.syncMtrbTableData = data.list;
        this.syncMtrbTotalPage = data.total;
        this.syncLoadFlag = false;
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
