import { getDateNoTime } from '@assets/js/dateUtils';

export const tableProperties = [
  { prop: 'selection', type: 'selection', labelWidth: '48px' },
  { prop: 'index', type: 'index', labelWidth: '50px', label: '序号' },
  {
    prop: 'mtrb_type',
    label: '材料类别',
    itemType: 'select',
    labelWidth: '100px',
    options: [
      { value: 1, label: '辅料' },
      { value: 2, label: '包材' },
      { value: 3, label: '猫草' }
    ],
    formatter: val => (val === 1 ? '辅料' : val === 2 ? '包材' : '猫草')
  },
  { prop: 'cust_catalog_number', label: '客户货号', itemType: 'input', labelWidth: '120px' },
  {
    prop: 'custAbbrList',
    label: '客户简称',
    itemType: 'select',
    options: [],
    labelWidth: '220px',
    collapseTags: true,
    multiple: true,
    needOtherColumn: true,
    formatter: row => row.cust_abbr
  },
  { prop: 'mtrb_name', label: '中文品名', itemType: 'input', labelWidth: '150px' },
  { prop: 'supp_abbr', label: '供应商简称', itemType: 'input', labelWidth: '150px' },
  {
    prop: 'mtrb_pctype',
    label: '包材品类',
    itemType: 'select',
    labelWidth: '100px',
    options: []
  },
  {
    prop: 'mtrb_price',
    label: '采购价',
    itemType: 'input',
    labelWidth: '100px',
    align: 'right',
    input: false,
    sortable: false,
    needOtherColumn: true
  },
  { prop: 'mtrb_no', label: '材料编号', itemType: 'input', labelWidth: '140px', input: false, sortable: false },
  {
    prop: 'mtrb_inrate',
    label: '增值税率',
    itemType: 'input',
    labelWidth: '100px',
    input: false,
    sortable: false
  },
  {
    prop: 'mtrb_version_no',
    label: '版本号',
    itemType: 'input',
    labelWidth: '100px',
    input: false,
    sortable: false
  },
  { prop: 'mtrb_unit', label: '数量单位', itemType: 'input', labelWidth: '100px', input: false, sortable: false },
  { prop: 'stff_name', label: '录入人', itemType: 'input', labelWidth: '100px', sortable: false },
  { prop: 'create_time', label: '录入时间', itemType: 'date', labelWidth: '100px', formatter: val => getDateNoTime(val, true) },
  { prop: 'mtrb_description', label: '产品描述', itemType: 'input', labelWidth: '100px', input: true, sortable: false },
  {
    prop: 'status',
    label: '单据状态',
    itemType: 'select',
    fixed: 'right',
    labelWidth: '150px',
    options: [
      { value: 0, label: '草拟' },
      { value: 1, label: '在批' },
      { value: 2, label: '生效' }
    ]
  }
];
export const syncMtrbTableProperties = [
  { prop: 'index', type: 'index', labelWidth: '50px', label: '序号' },
  { prop: 'mtrb_pctype', label: '类别', itemType: 'input', labelWidth: '150px', widthAuto: true },
  { prop: 'mtrb_name', label: '中文品名', itemType: 'input', labelWidth: '220px', widthAuto: true },
  { prop: 'cust_catalog_number', label: '客户货号', itemType: 'input', labelWidth: '220px', widthAuto: true },
  { prop: 'supp_abbr', label: '供应商简称', itemType: 'input', labelWidth: '150px', widthAuto: true },
  { prop: 'mtrb_unit', label: '数量单位', itemType: 'input', labelWidth: '150px', input: false, sortable: false, widthAuto: true },
  {
    prop: 'mtrb_price',
    label: '采购价',
    itemType: 'input',
    labelWidth: '150px',
    input: false,
    sortable: false,
    needOtherColumn: true,
    widthAuto: true
  }
];
